import { produce } from 'immer';
import { DEFAULT_SETTINGS } from '@pkg/auth/useDisplaySettings';
import config from '@/lib/config';
import { ExportableImageTypes } from '@/components/Design/enums';

export const INITIAL_OPTIONS_STATE = Object.freeze({
  conditions: config.DEFAULT_ENTITIES,
  dragItem: null,
  exportAs: {
    [ExportableImageTypes.PNG]: true,
    [ExportableImageTypes.SVG]: true,
  },
  focus: null,
  initial: null,
  layout: null,
  lens: null,
  level: null,
  scale: 1,
  tags: false,
  settings: DEFAULT_SETTINGS,
});

export default function createOptionsSlice(set) {
  return {
    ...INITIAL_OPTIONS_STATE,
    setConditions: (conditions) => {
      console.debug('DesignStore.setConditions', conditions);
      return set({ conditions });
    },
    setDragItem: (dragItem) => {
      console.debug('DesignStore.setDragItem', dragItem);
      return set({ dragItem });
    },
    setExportAs: (exportAs) => {
      console.debug('DesignStore.setExportAs', exportAs);

      return set(
        produce((state) => {
          Object.entries(exportAs).forEach(([key, value]) => {
            state.exportAs[key] = value;
          });
        })
      );
    },
    setFocus: (focus) => {
      console.debug('DesignStore.setFocus', focus);
      return set({ focus });
    },
    setInitial: (initial) => {
      console.debug('DesignStore.setInitial', initial);
      return set({ initial });
    },
    setLayout: (layout) => {
      console.debug('DesignStore.setLayout', layout);
      return set({ layout });
    },
    setLens: (lens) => {
      console.debug('DesignStore.setLens', lens);
      return set({ lens });
    },
    setLevel: (level) => {
      console.debug('DesignStore.setLevel', level);
      return set({ level });
    },
    setScale: (scale) => {
      console.debug('DesignStore.setScale', scale);
      return set({ scale });
    },
    setSettings: (settings) => {
      console.debug('DesignStore.setSettings', settings);
      return set({ settings });
    },
    setTags: (tags) => {
      console.debug('DesignStore.setTags', tags);
      return set({ tags });
    },
  };
}
