/**
 * @param {any} maybeArray
 * @returns {boolean}
 */
function isArray(maybeArray) {
  return Array.isArray(maybeArray);
}

/**
 * @param {*} maybeObject
 * @return {boolean}
 */
function isObject(maybeObject) {
  return typeof maybeObject === 'object' && maybeObject !== null;
}

/**
 * @param {*} maybeFile
 * @return {boolean}
 */
function isFile(maybeFile) {
  return maybeFile instanceof File;
}

/**
 * Handle recursion dive
 * @param {Object} item
 * @return {Object}
 */
function dive(item) {
  let ommited = {};

  switch (true) {
    case isFile(item):
    case !isObject(item):
      ommited = item;
      break;

    case isArray(item):
      const items = [];
      for (const [key, value] of Object.entries(item)) {
        if (!key.startsWith('__')) {
          items[key] = dive(value);
        }
      }

      ommited = items;
      break;

    default:
      for (const [key, value] of Object.entries(item)) {
        if (!key.startsWith('__')) {
          if (isObject(value)) {
            ommited[key] = dive(value);
          } else {
            ommited[key] = value;
          }
        }
      }
  }

  return ommited;
}

/**
 * Removes properties that begin with "__" these are assumed derived
 * @param {object} item
 */
export default function omitDerived(item) {
  console.time('Obj.omitDerived');
  const ommited = dive(item);
  console.timeEnd('Obj.omitDerived');
  return ommited;
}
