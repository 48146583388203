import { useEffect, useMemo, useState } from 'react';
import { DesignEntity } from '@/lib/enums';

const VALID_DIALOG = new Set(['prioritize']);
const VALID_PARTS = new Set(['groups', 'roles']);

/**
 * @param {any} value
 * @returns {Array}
 */
const extractParts = (value) => {
  return value?.match(/([a-z]+:\[([a-zA-Z0-9],?)+\])/g) ?? [];
};

/**
 * @param {any} value
 * @returns {Object}
 */
const parseExpandedParts = (value) => {
  const parts = extractParts(value);
  const expanded = {};

  parts.forEach((part) => {
    const [key, value] = part.split(':');

    if (!VALID_PARTS.has(key)) {
      return;
    }

    expanded[key] = value.substring(1, value.length - 1).split(',');
  });

  return Object.keys(expanded).length === 0 ? undefined : expanded;
};

/**
 * @param {String} key
 * @param {any} value
 * @returns {Object}
 */
const parseSelectedParts = (value) => {
  const parts = extractParts(value);
  if (!parts) {
    return;
  }

  const ids = [];
  parts.forEach((part) => {
    const [key, value] = part.split(':');

    if (!VALID_PARTS.has(key)) {
      return;
    }

    value
      .substring(1, value.length - 1)
      .split(',')
      .forEach((id) => ids.push(id));
  });

  return { type: DesignEntity.ROLE, ids };
};

/**
 * @param {String} key
 * @param {any} value
 * @returns {any|undefined}
 */
const parseValue = (key, value) => {
  switch (key) {
    case 'dialog':
      return VALID_DIALOG.has(value) ? value : undefined;

    case 'select':
      return parseSelectedParts(value);

    case 'expand':
      return parseExpandedParts(value);

    case 'layout':
      return value;

    default:
      return undefined;
  }
};

/**
 * @param {String[][]} parts
 * @param {String[]} trim
 */
const trim = (parts, trim) => {
  const trimSet = new Set(trim);
  const trimmed = [];

  parts.forEach(([key, value]) => {
    if (!trimSet.has(key)) {
      trimmed.push(`${key}=${value}`);
    }
  });

  return trimmed.length > 0 ? trimmed.join(';') : '';
};

/**
 * @returns {Object}
 */
export default function useLocation() {
  const [hash, setHash] = useState(window.location.hash);
  const properties = {};

  const trimKeys = [];
  const parts = hash
    .substring(1)
    .split(';')
    .map((part) => part.split('=', 2));

  parts.forEach(([key, value]) => {
    const parsed = parseValue(key, value);

    if (parsed === undefined) {
      trimKeys.push(key);
      return;
    }

    properties[key] = parsed;
  });

  const handleTrim = (keys) => {
    if (!window.location.hash) {
      return;
    }

    const result = trim(parts, keys);
    let pathname = window.location.pathname;
    if (result) {
      pathname += `#${result}`;
    }

    window.history.replaceState(undefined, undefined, pathname);
    setHash(result);
  };

  if (trimKeys.length > 0) {
    handleTrim(trimKeys);
  }

  useEffect(() => {
    if (hash !== window.location.hash) {
      setHash(window.location.hash);
    }
  }, [window.location.hash]);

  return useMemo(() => {
    return [properties, (key) => handleTrim([key])];
  }, [JSON.stringify(properties)]);
}
