import ucfirst from './ucfirst';

export default function camel(string, splits = ['.', '-', '_']) {
  let result = String(string);

  for (const split of splits) {
    const parts = result.split(split);
    result = [
      parts.shift().toLowerCase(),
      ...parts.map((part) => ucfirst(part.toLowerCase())),
    ].join('');
  }

  return result;
}
