import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@pkg/mui-tus-avatar';
import { Colors } from '@pkg/utils';
import config from '@/lib/config';
import Base from '../Base';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: ({ color, hasAvatar }) =>
      hasAvatar ? color[600] : palette.primary.main,
  },
  children: {
    color: 'white',
    height: ({ size }) => size,
    width: ({ size }) => size,
    fontSize: ({ size }) => size / 2,
    lineHeight: 2,
    textAlign: 'center',
  },
}));

const EditableAvatar = ({ user, size, ...props }) => {
  const hasAvatar = Boolean(user?.avatar);
  const color = Colors.seededOld(user?.email); /** @todo color */
  const classes = useStyles({ color, size, hasAvatar });

  return (
    <Base
      user={user}
      component={
        <Avatar
          classes={classes}
          endpoint={config.UPLOADS_HOST}
          rounded
          height={size}
          width={size}
        />
      }
      {...props}
    />
  );
};

EditableAvatar.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
};

export default EditableAvatar;
