import PropTypes from 'prop-types';
import { memo, useMemo, useState } from 'react';
import { DEFAULT_ACTIVITY_DESCRIPTION } from '@/shared/hooks/useDefaultActivity';
import TextareaAutosize from '@mui/core/TextareaAutosize';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { Activities } from '@pkg/entities/library';
import { useLocalState } from '@pkg/hooks';
import config from '@/lib/config';
import excludeProps from '@/lib/theme/excludeProps';
import { color, palettes } from '@/lib/theme/tokens';
import Typography from '@/components/Typography';
import truncate from '../utils/truncate';

const MAX_LENGTH = config.VALUE_LENGTH.MAX.ACTIVITY_DESCRIPTION;

const Root = styled(FormControl, {
  shouldForwardProp: excludeProps(['fullWidth']),
})(({ fullWidth }) => ({
  width: fullWidth ? '100%' : 'auto',
}));

const Option = styled('li')({
  cursor: 'pointer',
  display: 'flex',
  padding: 6,
  '&:hover': {
    backgroundColor: palettes.grey.colors[100],
  },
});

const InputLabel = styled(FormLabel)({
  lineHeight: '20px',
});

const OptionLabel = styled(Typography)({});

const GroupHeader = styled(ListSubheader)({
  backgroundColor: palettes.grey.colors[100],
  fontSize: 13,
  lineHeight: '2.5',
  margin: '12px 0 8px',
  '&:first-of-type': {
    marginTop: 0,
  },
});

const Input = styled(TextareaAutosize)(({ readOnly }) => ({
  backgroundColor: color.core.white,
  border: `2px solid ${palettes.grey.colors[200]}`,
  borderRadius: 4,
  cursor: readOnly ? 'default' : 'inherit',
  color: palettes.grey.colors[700],
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  outline: 0,
  padding: '6px 12px',
  resize: 'none',
  width: '100%',
  '&:hover': {
    borderColor: palettes.grey.colors[300],
  },
  '&:focus': {
    borderColor: palettes.brand.navy.colors[800],
  },
}));

const ActivitiesInput = ({
  autoFocus = true,
  disableCreation = false,
  exclude = new Set(),
  fullWidth = false,
  label = 'Activity description',
  name,
  onChange,
  readOnly = false,
  value = null,
}) => {
  const mapped = Activities.useStore((state) => state.mapped);
  const index = Activities.useStore((state) => state.index);
  const isLoading = Activities.useStore((state) => state.isLoading);

  const [localValue, setLocalValue] = useLocalState(value);
  const [search, setSearch] = useState(null);

  const isSearching = search !== null;
  const inputValue = isSearching ? search : (localValue?.description ?? '');

  const handleBlur = () => {
    if (!inputValue) {
      setLocalValue(value);
    }

    setSearch(null);
  };

  const handleInputChange = (_, value) => {
    const truncated = truncate(value, MAX_LENGTH);
    setSearch(truncated);
  };

  const handleChange = (event, value) => {
    const type = event.nativeEvent.type;

    if (!type === 'click') {
      return;
    }

    setLocalValue(value);
    setSearch(null);

    onChange?.(event, value);
  };

  const options = useMemo(() => {
    if (!isSearching) {
      return [];
    }

    const existing = new Set();
    const results = [];

    index.search(search).forEach((uuid) => {
      if (exclude.has(uuid)) {
        return;
      }

      const { description } = mapped.get(uuid);

      if (description === DEFAULT_ACTIVITY_DESCRIPTION) {
        return;
      }

      existing.add(description);
      const option = {
        description,
        uuid,
      };

      if (!disableCreation) {
        option.group = 'Library activities';
      }

      results.push(option);
    });

    if (
      !disableCreation &&
      !existing.has(search.toLowerCase()) &&
      Boolean(search)
    ) {
      results.push({
        group: 'Create new activity',
        description: search,
        uuid: 'new',
      });
    }

    return results;
  }, [index, search]);

  return (
    <Root fullWidth={fullWidth}>
      <Autocomplete
        autoHighlight
        blurOnSelect
        disableClearable
        freeSolo
        fullWidth
        handleHomeEndKeys
        selectOnFocus={!readOnly}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option?.description ?? option}
        groupBy={(option) => option.group}
        inputValue={inputValue}
        loading={isLoading}
        loadingText="Searching&hellip;"
        onBlur={handleBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        readOnly={readOnly}
        renderGroup={({ children, group, key }) => (
          <div key={key}>
            <GroupHeader>{group}</GroupHeader>
            {children}
          </div>
        )}
        renderOption={(props, option) => (
          <Option {...props} key={option.uuid}>
            <OptionLabel component="p" variant="body2">
              {option.uuid === 'new'
                ? `"${option.description}"`
                : option.description}
            </OptionLabel>
          </Option>
        )}
        renderInput={({ InputLabelProps, InputProps, ...params }) => {
          delete InputProps.className;
          delete params.inputProps.className;
          params.inputProps.minRows = 2;

          return (
            <>
              <InputLabel {...InputLabelProps}>{label}</InputLabel>
              <OutlinedInput
                {...InputProps}
                {...params}
                autoFocus={autoFocus}
                id={`input-${name}`}
                inputComponent={Input}
                name={name}
                placeholder="Start typing to search"
              />
            </>
          );
        }}
        value={inputValue}
      />
    </Root>
  );
};

ActivitiesInput.propTypes = {
  autoFocus: PropTypes.bool,
  disableCreation: PropTypes.bool,
  exclude: PropTypes.instanceOf(Set),
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.object,
};

export default memo(ActivitiesInput);
