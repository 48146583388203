import { Sort } from '@pkg/utils';

const formatTag = (tag) => ({
  name: tag.name,
  color: tag.color,
});

export default function getSummarised(tags, entityTagIds, format = formatTag) {
  if (tags.list.length === 0 || !entityTagIds) {
    return [];
  }

  const filtered = entityTagIds
    .map((uuid) => tags.map.get(uuid))
    .filter(Boolean)
    .filter((tag) => tag.is_disabled === false)
    .sort(Sort.order());

  filtered.sort(Sort.order());

  const summarised = filtered.reduce((acc, tag) => {
    acc[tag.uuid] = format(tag);
    return acc;
  }, {});

  return Object.values(summarised);
}
