import { Collections } from '@pkg/utils';
import { DesignLevel } from '@/lib/enums';
import { getReduced } from '../../utils';

/**
 * @param {Set} removing
 * @param {Object} groups
 * @param {Object} group
 */
function findNearestRemainingId(removing, groups, group) {
  if (!group.group_uuid) {
    return null;
  }

  const parent = groups[group.group_uuid];
  if (!removing.has(group.group_uuid)) {
    return group.group_uuid;
  }

  return findNearestRemainingId(removing, groups, parent);
}

/**
 * @param {Object} snapshot
 * @param {String[]} ids
 * @returns {Object}
 */
export default function archive(snapshot, { ids, inclusive = false }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
        remove: [],
      },
      roles: {
        update: [],
        remove: [],
      },
      activities: {
        remove: [],
      },
    },
  };

  const groups = Collections.keyById(snapshot.entities.groups);
  const removing = new Set(ids);
  const regrouped = new Map();

  // remove groups and regroup children
  snapshot.entities.groups.forEach((group) => {
    const nearestId = findNearestRemainingId(removing, groups, group);
    regrouped.set(group.uuid, nearestId);

    if (removing.has(group.group_uuid)) {
      mutation.entities.groups.update.push({
        uuid: group.uuid,
        group_uuid: nearestId,
        updated_at: now,
      });
    }

    if (!removing.has(group.uuid)) {
      return;
    }

    mutation.entities.groups.remove.push(group.uuid);

    if (inclusive) {
      const reduced = getReduced(snapshot, {
        type: DesignLevel.GROUP,
        uuid: group.uuid,
      });

      const reducedGroup = reduced.entities.groups.find(
        ({ uuid }) => uuid === group.uuid
      );

      let rolesRemoved = reduced.entities.roles.map(({ uuid }) => uuid);

      const parentRoles = Array.from(reducedGroup.__above.ROLE);

      rolesRemoved = rolesRemoved.filter((uuid) => !parentRoles.includes(uuid));

      mutation.entities.roles.remove = rolesRemoved;

      snapshot.entities.activities.forEach((activity) => {
        if (rolesRemoved.includes(activity.owner_uuid)) {
          mutation.entities.activities.remove.push(activity.uuid);
        }
      });
    }
  });

  // update role groups
  snapshot.entities.roles.forEach(({ uuid, group_uuid }) => {
    if (!removing.has(group_uuid)) {
      return;
    }

    mutation.entities.roles.update.push({
      uuid,
      group_uuid: regrouped.get(group_uuid),
      updated_at: now,
    });
  });

  // remove group-owned activities
  snapshot.entities.activities.forEach(({ uuid, owner_uuid }) => {
    if (removing.has(owner_uuid)) {
      mutation.entities.activities.remove.push(uuid);
    }
  });

  return mutation;
}
