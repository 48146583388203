import { useEffect, useState } from 'react';
import { DimensionPresetMap } from '../utils';

const useDimensionPresets = (api, dimensionType) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();

  const load = (option) => {
    if (!option.id) {
      return;
    }
    const presetState = DimensionPresetMap?.get(dimensionType)?.get(
      option.id
    )?.preset;

    if (presetState) {
      api.applyColumnState({ state: presetState });
    } else {
      api.resetColumnState();
    }

    setSelected(option);
  };

  const savePreset = () => {
    // Get the list of presets and append this one with a id.
    const columnState = api.getColumnState();
  };

  useEffect(() => {
    const preset = DimensionPresetMap.get(dimensionType);

    if (!api) {
      setOptions([]);
      return;
    }

    if (!preset) {
      setOptions([]);
      api.resetColumnState();
      return;
    }

    const options = [...preset.entries()]?.map(
      ([id, { defaultOption, label }]) => {
        return {
          label,
          defaultOption,
          id,
        };
      }
    );

    setOptions(options);

    const selected =
      options.find(({ defaultOption }) => defaultOption) ?? options[0];
    load(selected.id);
    setSelected(selected);
  }, [dimensionType, api]);

  return {
    savePreset,
    load,
    options,
    selected,
  };
};

export default useDimensionPresets;
