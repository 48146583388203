/**
 * @param {number} value
 * @param {object} options // Intl.NumberFormat options
 * @return {string}
 */
export default function format(value, options = {}) {
  if (isNaN(value) || value === null || value === '') {
    return null;
  }

  const formatter = new Intl.NumberFormat('en', options);
  return formatter.format(value);
}
