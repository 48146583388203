import { TagColor } from '@/lib/enums';
import { Arr } from '..';

const CACHE = new Map();
const SET = Object.keys(TagColor);

/**
 * @param {string} seed
 * @param {boolean} [shades]
 * @return {string}
 */
export default function seeded(seed = 0) {
  const isCached = CACHE.has(seed);
  const key = isCached ? CACHE.get(seed) : Arr.seededShuffle(SET, seed)[0];
  CACHE.set(seed, key);
  return key;
}
